import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environmentConfig } from "../environment";
import {
  Product,
  Subscriptions,
  ProductPaymentUrlResponse,
  ProductsResponse,
  SubscriptionsResponse,
  UserCheckInData,
  UserCheckInResponse,
  aeDeltaPerformanceReportResponse,
  aePerformanceReportResponse,
  branchAndLoanOfficerPerformanceReportResponse,
  tsDataResultsResponse,
  tsDataObject,
  settingsObject,
  Commons_GetAppSettingsResult,
} from "./types/gallus";
import { getUserSessionItem, updateSAToken } from "../";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import Bugsnag from "@bugsnag/js";

/* function isFetchArgs(args: string | FetchArgs): args is FetchArgs {
  return typeof args !== "string";
} */

const gallusApiBaseURL: FetchBaseQueryArgs = {
  baseUrl: environmentConfig.backendApiEndpoint,
  prepareHeaders: (headers) => {
    headers.set("SAToken", getUserSessionItem("SAToken") ?? "");
    return headers;
  },
};

const gallusAgilityApiBaseURL: FetchBaseQueryArgs = {
  baseUrl: "https://ga-api.gallusinsights.co",
};

const baseQuery = fetchBaseQuery(gallusApiBaseURL);
const baseQueryWithLogging: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (endpointsToLog.includes(api.endpoint)) {
    const bodyFormData = new URLSearchParams();
    const accessToken = getUserSessionItem("accessToken");
    bodyFormData.set("UserPayload", getUserSessionItem("user") ?? "Error");
    baseQuery(
      {
        url:
          "/services/?service=UserActivity_Add&URL=" +
          window.location.pathname +
          "&ID_ActivityType=6",
        body: bodyFormData,
        headers: { "access-token": accessToken! },
        method: "post",
      },
      api,
      extraOptions,
    );
  }

  if (result.data) {
    const resultData = result.data as any;
    // const resultData = {
    //     retCode: 401
    // };
    switch (resultData.retCode as Number) {
      case 401:
        const bodyFormData = new URLSearchParams();
        bodyFormData.set("SAToken", getUserSessionItem("SAToken") ?? "");
        const newToken = await baseQuery(
          {
            url: "/services/?service=User_SATokenRefresh",
            body: bodyFormData,
            method: "post",
          },
          api,
          extraOptions,
        );
        if (newToken.data) {
          const newTokenData = newToken.data as any;
          updateSAToken(newTokenData.SAToken as string, "Refresh");
          result = await baseQuery(args, api, extraOptions);
        }
        break;
      case 403:
        console.log("Invalid Gallus Token. Logging out.");
        window.location.href = "/signout?error_code=invalid_token";
        break;
    }
  }
  if (result.error) {
    Bugsnag.notify(new Error("Gallus API Error"), function (event) {
      event.severity = "error";
      event.setUser(
        getUserSessionItem("userName") ?? "User not in UserSession",
      );
      event.addMetadata("API Arguments", {
        endpoint: api.endpoint,
        args: JSON.stringify(args),
      });
    });
  }
  return result;
};

const endpointsToLog: string[] = [
  "CHMAccountExecutives_ViewOrderedByMetricsMonthAndYear",
  "CHMBranches_ViewOrderedByMetricsMonthAndYear",
  "CHMLoanOfficers_ViewOrderedByMetricsMonthAndYear",
  "Demo_ViewOrderedByMetricsMonthAndYear",
  "TS_CHMAEsPerfSummary2YTD",
  "TS_Delta_View",
  "TrialBalance_GetFiscalYearFiscalQuarter",
  "TrialBalance_Get",
  "getGRateDates",
  "getGRateTableData",
  "TS_CHMWholesaleQTD",
  "TS_CHMWholesaleMTD",
  "TS_RetailDashboard",
];

export const gallusApi = createApi({
  reducerPath: "gallusApi",
  baseQuery: baseQueryWithLogging,
  endpoints: (builder) => ({
    getProducts: builder.query<Product[], void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "Product_View");
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: ProductsResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results as Product[];
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    getSubscriptions: builder.query<
      Subscriptions[],
      { cognitoSubject: string }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "Subscription_View");
        urlSearchParams.set("CognitoGUID", arg.cognitoSubject);
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: SubscriptionsResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results as Subscriptions[];
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    generateProductPaymentUrl: builder.query<
      string,
      { productId: string; cognitoSubject: string; backUrl?: string }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "PaymentURL_Get");
        urlSearchParams.set("ID_Product", arg.productId);
        urlSearchParams.set("CognitoGUID", arg.cognitoSubject);
        if (arg.backUrl) {
          urlSearchParams.set("BackURL", encodeURIComponent(arg.backUrl));
        }
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: ProductPaymentUrlResponse): string => {
        switch (response.retCode) {
          case 200:
            return response.results;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    userCheckIn: builder.query<UserCheckInData, { userPayload: any }>({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        let bodyFormData = new URLSearchParams();
        urlSearchParams.set("service", "User_Checkin2");
        bodyFormData.set("UserPayload", arg.userPayload);
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "post",
          body: bodyFormData,
        };
      },
      transformResponse: (response: UserCheckInResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results[0];
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    CHMAccountExecutives_ViewOrderedByMetricsMonthAndYear: builder.query<
      aePerformanceReportResponse,
      {
        year: string;
        month: string;
        timePeriod: "QTD" | "YTD" | "ALL" | undefined;
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "CHMAEsPerformanceReport_View");
        urlSearchParams.set("Year", arg.year);
        urlSearchParams.set("Month", arg.month);
        if (typeof arg.timePeriod !== "undefined") {
          urlSearchParams.set("TimePeriod", arg.timePeriod);
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: aePerformanceReportResponse) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    CHMBranches_ViewOrderedByMetricsMonthAndYear: builder.query<
      branchAndLoanOfficerPerformanceReportResponse,
      {
        year: string;
        month: string;
        timePeriod: "QTD" | "YTD" | "ALL" | undefined;
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "CHMBranches_ViewOrderedByMetrics");
        urlSearchParams.set("Year", arg.year);
        urlSearchParams.set("Month", arg.month);
        if (typeof arg.timePeriod !== "undefined") {
          urlSearchParams.set("TimePeriod", arg.timePeriod);
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (
        response: branchAndLoanOfficerPerformanceReportResponse,
      ) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    CHMLoanOfficers_ViewOrderedByMetricsMonthAndYear: builder.query<
      branchAndLoanOfficerPerformanceReportResponse,
      {
        year: string;
        month: string;
        timePeriod: "QTD" | "YTD" | "ALL" | undefined;
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "CHMLOs_ViewOrderedByMetrics");
        urlSearchParams.set("Year", arg.year);
        urlSearchParams.set("Month", arg.month);
        if (typeof arg.timePeriod !== "undefined") {
          urlSearchParams.set("TimePeriod", arg.timePeriod);
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (
        response: branchAndLoanOfficerPerformanceReportResponse,
      ) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    Demo_ViewOrderedByMetricsMonthAndYear: builder.query<
      aePerformanceReportResponse,
      {
        year: string;
        month: string;
        timePeriod: "QTD" | "YTD" | "ALL" | undefined;
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set(
          "service",
          "DemoAEPerformance2_ViewOrderedByMetrics",
        );
        urlSearchParams.set("Year", arg.year);
        urlSearchParams.set("Month", arg.month);
        if (typeof arg.timePeriod !== "undefined") {
          urlSearchParams.set("TimePeriod", arg.timePeriod);
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: aePerformanceReportResponse) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TS_CHMAEsPerfSummary2YTD: builder.query<tsDataObject, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CHMAEsPerfSummary2YTD");
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TS_Delta_View: builder.query<
      aeDeltaPerformanceReportResponse,
      { period: "MTD" | "QTD" | "YTD" }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        switch (arg.period) {
          case "MTD":
            urlSearchParams.set("service", "TS_DeltaMTD_View");
            break;
          case "QTD":
            urlSearchParams.set("service", "TS_DeltaQTD_View");
            break;
          case "YTD":
            urlSearchParams.set("service", "TS_DeltaYTD_View");
            break;
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TrialBalance_GetFiscalYearFiscalQuarter: builder.query<any, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set(
          "service",
          "TrialBalance_GetFiscalYearFiscalQuarter",
        );
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TrialBalance_Get: builder.query<any, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TrialBalance_Get");
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    getGRateDates: builder.query<
      string[],
      { table: "Hedge" | "AggregateTable" }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CustomQueryData");
        urlSearchParams.set("TSAPI_Name", arg.table + "Dates");
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get",
        };
      },
      transformResponse: (response: tsDataResultsResponse) => {
        switch (response.retCode) {
          case 200:
            const tmpArray: string[] = response.results.data.flat(1);
            tmpArray.sort(function (a, b) {
              a = a.split("/").reverse().join("");
              b = b.split("/").reverse().join("");
              return a > b ? 1 : a < b ? -1 : 0;
            });
            return tmpArray.reverse();
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    getGRateTableData: builder.query<
      tsDataResultsResponse,
      { table: "Hedge" | "AggregateTable"; period: string }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CustomQueryData");
        if (arg.table === "Hedge") {
          urlSearchParams.set("TSAPI_Name", "HedgePopulateTable");
        } else {
          urlSearchParams.set("TSAPI_Name", "AggregateTable");
        }
        urlSearchParams.set("Date", arg.period);
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get",
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TS_CHMWholesaleQTD: builder.query<tsDataObject, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CHMAEsPerfSummary2QTD");
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: tsDataResultsResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TS_CHMWholesaleMTD: builder.query<
      tsDataObject,
      { year: string; month: string }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CHMAEsPerfSummary2MTD");
        urlSearchParams.set("Year", arg.year);
        urlSearchParams.set("Month", arg.month);
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: tsDataResultsResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    TS_RetailDashboard: builder.query<
      tsDataObject,
      {
        timePeriod: "MTD" | "QTD" | "YTD";
        date?: { year: string; month: string };
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_RetailDashboard_View");
        urlSearchParams.set("TimePeriod", arg.timePeriod);
        if (typeof arg.date !== "undefined") {
          urlSearchParams.set("Year", arg.date.year);
          urlSearchParams.set("Month", arg.date.month);
        }
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: tsDataResultsResponse) => {
        switch (response.retCode) {
          case 200:
            return response.results;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    addUserActivity: builder.query<
      void,
      {
        URL: string;
        ID_ActivityType: string;
        Value1?: string;
        Value2?: string;
        Value3?: string;
      }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        let bodyFormData = new URLSearchParams();
        urlSearchParams.set("service", "UserActivity_Add");
        urlSearchParams.set("URL", arg.URL);
        urlSearchParams.set("ID_ActivityType", arg.ID_ActivityType);
        let accessToken = getUserSessionItem("accessToken");

        if (typeof arg.Value1 !== "undefined") {
          urlSearchParams.set("Value1", arg.Value1);
        }
        if (typeof arg.Value2 !== "undefined") {
          urlSearchParams.set("Value1", arg.Value2);
        }
        if (typeof arg.Value3 !== "undefined") {
          urlSearchParams.set("Value1", arg.Value3);
        }

        if (getUserSessionItem("user") !== null) {
          bodyFormData.set(
            "UserPayload",
            getUserSessionItem("user") ?? "Error",
          );
        }

        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "post",
          body: bodyFormData,
          headers: { "access-token": accessToken! },
        };
      },
    }),
    GI_GRate_DateCompare_View: builder.query<
      tsDataResultsResponse,
      { dateArray: string[] }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "GI_GRate_DateCompare_View2");
        arg.dateArray.forEach((date, index) => {
          urlSearchParams.set("Date" + (index + 1), date);
        });
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    GI_GRate_LocksValuesDay_View: builder.query<
      tsDataResultsResponse,
      { dateArray: string[] }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "GI_GRate_LocksValuesDay_View");
        arg.dateArray.forEach((date, index) => {
          urlSearchParams.set("Date" + (index + 1), date);
        });
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    GI_GRate_Summary_View: builder.query<
      tsDataResultsResponse,
      { dateArray: string[] }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "GI_GRate_Summary_View");
        arg.dateArray.forEach((date, index) => {
          urlSearchParams.set("Date" + (index + 1), date);
        });
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    GI_GRate_Summary_ViewTable2: builder.query<
      tsDataResultsResponse,
      { dateArray: string[] }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "GI_GRate_Summary_ViewTable2");
        arg.dateArray.forEach((date, index) => {
          urlSearchParams.set("Date" + (index + 1), date);
        });
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    GI_GRate_Summary_ViewTable3: builder.query<
      tsDataResultsResponse,
      { dateArray: string[] }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "GI_GRate_Summary_ViewTable3");
        arg.dateArray.forEach((date, index) => {
          urlSearchParams.set("Date" + (index + 1), date);
        });
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    BalanceSheet_Get: builder.query<any, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "BalanceSheet_Get");
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return response;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    LiveboardExternalSiteCheck: builder.query<
      number | undefined,
      { liveboardGUID: string }
    >({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "TS_CheckLiveboardInExternalSite");
        urlSearchParams.set("LiveboardGUID", arg.liveboardGUID);
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "get", //removed sectoken in body
        };
      },
      transformResponse: (response: any) => {
        switch (response.retCode) {
          case 200:
            return Number(response.results);
          case 400:
            return undefined;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
    commons_GetAppSettings: builder.query<settingsObject, void>({
      query: () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set("service", "Commons_GetAppSettings");
        return `/services/?${urlSearchParams.toString()}`;
      },
      transformResponse: (response: Commons_GetAppSettingsResult) => {
        switch (response.retCode) {
          case 200:
            return response.settings as settingsObject;
          default:
            throw new Error(JSON.stringify(response));
        }
      },
    }),
  }),
});

export const gallusAgilityApi = createApi({
  reducerPath: "gallusAgilityApi",
  baseQuery: fetchBaseQuery(gallusAgilityApiBaseURL),
  endpoints: (builder) => ({
    GA_User_Checkin: builder.query<UserCheckInData[], { userPayload: any }>({
      query: (arg) => {
        let urlSearchParams = new URLSearchParams();
        let bodyFormData = new URLSearchParams();
        urlSearchParams.set("service", "GA_User_Checkin");
        bodyFormData.set("UserPayload", arg.userPayload);
        return {
          url: `/services/?${urlSearchParams.toString()}`,
          method: "post",
          body: bodyFormData,
        };
      },
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useLazyGetSubscriptionsQuery,
  useGenerateProductPaymentUrlQuery,
  useLazyUserCheckInQuery,
  useLazyCHMAccountExecutives_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMBranches_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyCHMLoanOfficers_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyDemo_ViewOrderedByMetricsMonthAndYearQuery,
  useLazyTS_Delta_ViewQuery,
  useLazyTS_CHMAEsPerfSummary2YTDQuery,
  useTrialBalance_GetFiscalYearFiscalQuarterQuery,
  useTrialBalance_GetQuery,
  useGetGRateDatesQuery,
  useLazyGetGRateTableDataQuery,
  useLazyTS_CHMWholesaleQTDQuery,
  useLazyTS_CHMWholesaleMTDQuery,
  useLazyAddUserActivityQuery,
  useLazyTS_RetailDashboardQuery,
  useLazyGI_GRate_DateCompare_ViewQuery,
  useLazyGI_GRate_LocksValuesDay_ViewQuery,
  useLazyGI_GRate_Summary_ViewQuery,
  useLazyGI_GRate_Summary_ViewTable2Query,
  useLazyGI_GRate_Summary_ViewTable3Query,
  useBalanceSheet_GetQuery,
  useLiveboardExternalSiteCheckQuery,
  useLazyCommons_GetAppSettingsQuery,
} = gallusApi;

export const { useLazyGA_User_CheckinQuery } = gallusAgilityApi;
